import * as React from 'react';
import { useState } from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import { Layout } from 'src/components/Layout';
import { SEO } from 'src/components/SEO';
import { PageTitle } from 'src/components/PageTitle';
import { LogosSlider } from 'src/components/LogosSlider';
import { ReviewsCarousel } from 'src/components/ReviewsCarousel';
import { NewsCard } from 'src/components/News/NewsCard';
import { Button } from 'src/components/Button';

import newsCardPlaceholder from 'src/assets/images/newscard-placeholder.jpg';

const CareerPage = () => {
  const [showMore, setShowMore] = useState<boolean>(false);

  return (
    <Layout className="team">
      <SEO title="Career" />
      <div className="container">
        <PageTitle
          className="mb-6"
          title='You will experience with us...'
        />
        <div className="row mb-6 mb-lg-9">
          <div className="col-12 col-lg-4 mb-4">
            <h2>Quality chat</h2>
            <StaticImage
              className="team__graphic"
              src="../assets/images/career/career-graphic-01.png"
              alt="Team"
              layout='fullWidth'
            />
          </div>
          <div className="col-12 col-lg-4 mb-4">
            <h2>Connections</h2>
            <StaticImage
              className="team__graphic"
              src="../assets/images/career/career-graphic-02.png"
              alt="Team"
              layout='fullWidth'
            />
          </div>
          <div className="col-12 col-lg-4 mb-4">
            <h2>Feedback</h2>
            <StaticImage
              className="team__graphic"
              src="../assets/images/career/career-graphic-03.png"
              alt="Team"
              layout='fullWidth'
            />
          </div>
          <div className="col-12 col-lg-4 mb-4">
            <h2>Offer</h2>
            <StaticImage
              className="team__graphic"
              src="../assets/images/career/career-graphic-04.png"
              alt="Team"
              layout='fullWidth'
            />
          </div>
          <div className="col-12 col-lg-4 mb-4">
            <h2>Start day</h2>
            <StaticImage
              className="team__graphic"
              src="../assets/images/career/career-graphic-05.png"
              alt="Team"
              layout='fullWidth'
            />
          </div>
          <div className="col-12 col-lg-4 mb-4">
            <h2>Future</h2>
            <StaticImage
              className="team__graphic"
              src="../assets/images/career/career-graphic-06.png"
              alt="Team"
              layout='fullWidth'
            />
          </div>
        </div>
      </div>
      <LogosSlider />
      <ReviewsCarousel className="mb-10" />
      {/*<div className="container pt-7 pb-3">
        <PageTitle title="All the great things we have done..." />
        <div className="row mt-2">
          <NewsCard
            image={newsCardPlaceholder}
            title="MSD: Jak jsme se dostali z 0 na 900!"
            readLength="5 min"
          />
          <NewsCard
            image={newsCardPlaceholder}
            title="MSD: Jak jsme se dostali z 0 na 900!"
            readLength="5 min"
          />
          <NewsCard
            image={newsCardPlaceholder}
            title="MSD: Jak jsme se dostali z 0 na 900!"
            readLength="5 min"
          />
          {
            showMore &&
              <>
                <NewsCard
                  image={newsCardPlaceholder}
                  title="MSD: Jak jsme se dostali z 0 na 900!"
                  readLength="5 min"
                />
                <NewsCard
                  image={newsCardPlaceholder}
                  title="MSD: Jak jsme se dostali z 0 na 900!"
                  readLength="5 min"
                />
              </>
          }
        </div>
        {
          !showMore &&
            <div className="row mt-2 mb-10">
              <div className="d-flex col-12">
                <Button
                  className="mx-auto"
                  onClick={() => setShowMore(true)}
                >
                  See all
                </Button>
              </div>
            </div>
        }
      </div>*/}
    </Layout>
  );
};

export default CareerPage;
